import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getI18n } from 'react-i18next';
import moment from 'moment';

import { request } from './actions';
import Loading from './components/loading';

export const AuthContext = createContext({ user: {} });

const AuthContextProvider = ({ children, ...props }) => {
  const [user, setUser] = useState(props.user || null);

  useEffect(() => {
    if (user !== null) return;

    (async () => {
      const params = {};
      window.location.search
        .substring(1)
        .split('&')
        .forEach((param) => {
          const keyValue = param.split('=');
          params[keyValue.shift()] = keyValue.join('=');
        });

      const authToken = params.message;

      if (authToken !== undefined) {
        const res = await fetch(`${window.location.pathname}${window.location.search}`);
        window.location = res.location || '/';
      }

      const [response] = await request('/current_user', {
        method: 'GET',
      });

      if (response.status === 403 || response.status === 404) {
        window.location = `${process.env.REACT_APP_GATEKEEPER_URL}/session?host=${window.location.host}&return_url=${window.location.pathname}`;
      } else {
        getI18n().changeLanguage(response.data.attributes.locale);
        moment.locale(response.data.attributes.locale);
        setUser(response.data.attributes);
      }
    })();
  }, [user]);

  if (user === null) {
    return <Loading />;
  }
  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

export default AuthContextProvider;
