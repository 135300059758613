import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import AuthContextProvider from '../../auth-context';

import Loading from '../loading';
import Navbar from '../navbar';
import NavItems from '../navbar/nav-items';

import './app.scss';

const AuditQuestion = lazy(() => import('../../containers/audit-question'));
const AuditQuestions = lazy(() => import('../../containers/audit-questions'));
const Branch = lazy(() => import('../../containers/branch'));
const Branches = lazy(() => import('../../containers/branches'));
const Columns = lazy(() => import('../../containers/columns'));
const DataOption = lazy(() => import('../../containers/data-option'));
const DataOptions = lazy(() => import('../../containers/data-options'));
const DataSubject = lazy(() => import('../../containers/data-subject'));
const DataSubjects = lazy(() => import('../../containers/data-subjects'));
const DeviationQuestion = lazy(() => import('../../containers/deviation-question'));
const DeviationQuestions = lazy(() => import('../../containers/deviation-questions'));
const Document = lazy(() => import('../../containers/document'));
const Documents = lazy(() => import('../../containers/documents'));
const DpiaProject = lazy(() => import('../../containers/dpia-project'));
const DpiaProjects = lazy(() => import('../../containers/dpia-projects'));
const DpiaQuestion = lazy(() => import('../../containers/dpia-question'));
const DpiaQuestions = lazy(() => import('../../containers/dpia-questions'));
const EditNorm = lazy(() => import('../../containers/edit-norm'));
const ExtendedManualParagraphContent = lazy(() => import('../../containers/extended-manual-paragraph-content'));
const ExtendedNormContent = lazy(() => import('../../containers/extended-norm-content'));
const JobRole = lazy(() => import('../../containers/job-role'));
const JobRoleProcessInvolved = lazy(() => import('../../containers/job-role-process-involved'));
const JobRoles = lazy(() => import('../../containers/job-roles'));
const JobTitle = lazy(() => import('../../containers/job-title'));
const JobTitleJobRole = lazy(() => import('../../containers/job-title-job-role'));
const JobTitles = lazy(() => import('../../containers/job-titles'));
const ManualChapter = lazy(() => import('../../containers/manual-chapter'));
const ManualChapters = lazy(() => import('../../containers/manual-chapters'));
const ManualParagraph = lazy(() => import('../../containers/manual-paragraph'));
const ManualParagraphPreview = lazy(() => import('../../containers/manual-paragraph-preview'));
const ManualParagraphProcessInvolved = lazy(() => import('../../containers/manual-paragraph-process-involved'));
const ManualParagraphProcessInvolveds = lazy(() => import('../../containers/manual-paragraph-process-involveds'));
const NewAuditQuestion = lazy(() => import('../../containers/new-audit-question'));
const NewBranch = lazy(() => import('../../containers/new-branch'));
const NewDataOption = lazy(() => import('../../containers/new-data-option'));
const NewDataSubject = lazy(() => import('../../containers/new-data-subject'));
const NewDeviationQuestion = lazy(() => import('../../containers/new-deviation-question'));
const NewDocument = lazy(() => import('../../containers/new-document'));
const NewDpiaProject = lazy(() => import('../../containers/new-dpia-project'));
const NewDpiaQuestion = lazy(() => import('../../containers/new-dpia-question'));
const NewJobRole = lazy(() => import('../../containers/new-job-role'));
const NewJobRoleProcessInvolved = lazy(() => import('../../containers/new-job-role-process-involved'));
const NewJobTitle = lazy(() => import('../../containers/new-job-title'));
const NewJobTitleJobRole = lazy(() => import('../../containers/new-job-title-job-role'));
const NewManualChapter = lazy(() => import('../../containers/new-manual-chapter'));
const NewManualParagraphProcessInvolved = lazy(() => import('../../containers/new-manual-paragraph-process-involved'));
const NewNorm = lazy(() => import('../../containers/new-norm'));
const NewNormColumn = lazy(() => import('../../containers/new-norm-column'));
const NewNormRequirement = lazy(() => import('../../containers/new-norm-requirement'));
const NewProcessFlow = lazy(() => import('../../containers/new-process-flow'));
const NewSupplierLicenseExport = lazy(() => import('../../containers/new-supplier-license-export'));
const NewTable = lazy(() => import('../../containers/new-table'));
const NewThreat = lazy(() => import('../../containers/new-threat'));
const NewTaskPackage = lazy(() => import('../../containers/new-task-package'));
const NewTaskPackageTask = lazy(() => import('../../containers/new-task-package-task'));
const Norm = lazy(() => import('../../containers/norm'));
const NormChapter = lazy(() => import('../../containers/norm-chapter'));
const NormColumn = lazy(() => import('../../containers/norm-column'));
const NormLinks = lazy(() => import('../../containers/norm-links'));
const NormRequirement = lazy(() => import('../../containers/norm-requirement'));
const NormRequirements = lazy(() => import('../../containers/norm-requirements'));
const Norms = lazy(() => import('../../containers/norms'));
const ProcessFlow = lazy(() => import('../../containers/process-flow'));
const ProcessFlows = lazy(() => import('../../containers/process-flows'));
const SupplierLicenseExport = lazy(() => import('../../containers/supplier-license-export'));
const SupplierLicenseExports = lazy(() => import('../../containers/supplier-license-exports'));
const Table = lazy(() => import('../../containers/table'));
const TableTextBlocks = lazy(() => import('../../containers/table-text-blocks'));
const Tables = lazy(() => import('../../containers/tables'));
const Threat = lazy(() => import('../../containers/threat'));
const Threats = lazy(() => import('../../containers/threats'));
const TaskPackageTask = lazy(() => import('../../containers/task-package-task'));
const TaskPackage = lazy(() => import('../../containers/task-package'));
const TaskPackages = lazy(() => import('../../containers/task-packages'));
const TextBlock = lazy(() => import('../../containers/text-block'));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <AuthContextProvider>
          <Navbar />
          <div className="container-fluid">
            <div className="row main-container">
              <div className="col-1 sidebar sidebar__navbar d-none d-lg-block">
                <ul className="sidebar__navbar__menu">
                  <NavItems />
                </ul>
              </div>
              <div className="col main-content">
                <Routes>
                  <Route path="/" element={<AuditQuestions />} />
                  <Route path="/audit-questions" element={<AuditQuestions />} exact={true} />
                  <Route path="/audit-questions/new" element={<NewAuditQuestion />} exact={true} />
                  <Route path="/audit-questions/:id" element={<AuditQuestion />} exact={true} />
                  <Route path="/branches" element={<Branches />} exact={true} />
                  <Route path="/branches/new" element={<NewBranch />} exact={true} />
                  <Route path="/branches/:id" element={<Branch />} exact={true} />
                  <Route path="/data-options" element={<DataOptions />} exact={true} />
                  <Route path="/data-options/new" element={<NewDataOption />} exact={true} />
                  <Route path="/data-options/:id" element={<DataOption />} exact={true} />
                  <Route path="/data-subjects" element={<DataSubjects />} exact={true} />
                  <Route path="/data-subjects/new" element={<NewDataSubject />} exact={true} />
                  <Route path="/data-subjects/:id" element={<DataSubject />} exact={true} />
                  <Route path="/deviation-questions" element={<DeviationQuestions />} exact={true} />
                  <Route path="/deviation-questions/new" element={<NewDeviationQuestion />} exact={true} />
                  <Route path="/deviation-questions/:id" element={<DeviationQuestion />} exact={true} />
                  <Route path="/documents" element={<Documents />} exact={true} />
                  <Route path="/documents/new" element={<NewDocument />} exact={true} />
                  <Route path="/documents/:id" element={<Document />} exact={true} />
                  <Route path="/dpia-projects" element={<DpiaProjects />} exact={true} />
                  <Route path="/dpia-projects/new" element={<NewDpiaProject />} exact={true} />
                  <Route path="/dpia-projects/:id" element={<DpiaProject />} exact={true} />
                  <Route path="/dpia-projects/:dpiaProjectId/dpia-questions" element={<DpiaQuestions />} exact={true} />
                  <Route
                    path="/dpia-projects/:dpiaProjectId/dpia-questions/new"
                    element={<NewDpiaQuestion />}
                    exact={true}
                  />
                  <Route
                    path="/dpia-projects/:dpiaProjectId/dpia-questions/:id"
                    element={<DpiaQuestion />}
                    exact={true}
                  />
                  <Route path="/job-roles" element={<JobRoles />} exact={true} />
                  <Route path="/job-roles/new" element={<NewJobRole />} exact={true} />
                  <Route path="/job-roles/:id" element={<JobRole />} exact={true} />
                  <Route
                    path="/job-roles/:jobRoleId/process-involveds/new"
                    element={<NewJobRoleProcessInvolved />}
                    exact={true}
                  />
                  <Route
                    path="/job-roles/:jobRoleId/process-involveds/:id"
                    element={<JobRoleProcessInvolved />}
                    exact={false}
                  />
                  <Route path="/job-titles" element={<JobTitles />} exact={true} />
                  <Route path="/job-titles/new" element={<NewJobTitle />} exact={true} />
                  <Route path="/job-titles/:id" element={<JobTitle />} exact={true} />
                  <Route
                    path="/job-titles/:jobTitleId/job-title-job-roles/new"
                    element={<NewJobTitleJobRole />}
                    exact={true}
                  />
                  <Route
                    path="/job-titles/:jobTitleId/job-title-job-roles/:id"
                    element={<JobTitleJobRole />}
                    exact={false}
                  />
                  <Route path="/manual-chapters" element={<ManualChapters />} exact={true} />
                  <Route path="/manual-chapters/new" element={<NewManualChapter />} exact={true} />
                  <Route path="/manual-chapters/:id" element={<ManualChapter />} exact={true} />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:id"
                    element={<ManualParagraph />}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/extended-norm-content"
                    element={<ExtendedNormContent />}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/process-involveds"
                    element={<ManualParagraphProcessInvolveds />}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/process-involveds/new"
                    element={<NewManualParagraphProcessInvolved />}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/process-involveds/:id"
                    element={<ManualParagraphProcessInvolved />}
                    exact={false}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/preview"
                    element={<ManualParagraphPreview />}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/norm-links"
                    element={<NormLinks />}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/text-blocks/:id"
                    element={<TextBlock />}
                    exact={true}
                  />
                  <Route path="/norms" element={<Norms />} exact={true} />
                  <Route path="/norms/new" element={<NewNorm />} exact={true} />
                  <Route path="/norms/:id" element={<Norm />} exact={true} />
                  <Route path="/norms/:id/edit" element={<EditNorm />} exact={true} />
                  <Route
                    path="/norms/:normId/extededContent"
                    element={<ExtendedManualParagraphContent />}
                    exact={true}
                  />
                  <Route path="/norms/:normId/norm-chapters/:id" element={<NormChapter />} exact={true} />
                  <Route path="/norms/:normId/norm-columns/new" element={<NewNormColumn />} exact={true} />
                  <Route path="/norms/:normId/norm-columns/:id" element={<NormColumn />} exact={true} />
                  <Route path="/norm-requirements" element={<NormRequirements />} exact={true} />
                  <Route path="/norm-requirements/new" element={<NewNormRequirement />} exact={true} />
                  <Route path="/norm-requirements/:id" element={<NormRequirement />} exact={true} />
                  <Route path="/process-flows" element={<ProcessFlows />} exact={true} />
                  <Route path="/process-flows/new" element={<NewProcessFlow />} exact={true} />
                  <Route path="/process-flows/:id" element={<ProcessFlow />} exact={true} />
                  <Route path="/supplier-license-exports" element={<SupplierLicenseExports />} exact={true} />
                  <Route path="/supplier-license-exports/new" element={<NewSupplierLicenseExport />} exact={true} />
                  <Route path="/supplier-license-exports/:id" element={<SupplierLicenseExport />} exact={true} />
                  <Route path="/tables" element={<Tables />} exact={true} />
                  <Route path="/tables/new" element={<NewTable />} exact={true} />
                  <Route path="/tables/:id" element={<Table />} exact={true} />
                  <Route path="/tables/:tableId/columns" element={<Columns />} exact={true} />
                  <Route path="/tables/:tableId/text-blocks" element={<TableTextBlocks />} exact={true} />
                  <Route path="/threats" element={<Threats />} exact={true} />
                  <Route path="/threats/new" element={<NewThreat />} exact={true} />
                  <Route path="/threats/:id" element={<Threat />} exact={true} />
                  <Route
                    path="/task-packages/:taskPackageId/tasks/new"
                    element={<NewTaskPackageTask />}
                    exact={false}
                  />
                  <Route
                    path="/task-packages/:taskPackageId/tasks/:taskId"
                    element={<TaskPackageTask />}
                    exact={false}
                  />
                  <Route path="/task-packages" element={<TaskPackages />} exact={true} />
                  <Route path="/task-packages/new" element={<NewTaskPackage />} exact={true} />
                  <Route path="/task-packages/:id" element={<TaskPackage />} exact={true} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </div>
          </div>
        </AuthContextProvider>
      </Suspense>
    </Router>
  );
};

export default App;
