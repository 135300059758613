import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faArrowProgress,
  faBadgeCheck,
  faBriefcase,
  faCabinetFiling,
  faClipboardListCheck,
  faFile,
  faFileCertificate,
  faGavel,
  faProjectDiagram,
  faQuestionCircle,
  faScroll,
  faTable,
  faTriangleExclamation,
  faUserCheck,
  faUserSecret,
} from '@fortawesome/pro-solid-svg-icons';
import { faBoxesStacked } from '@fortawesome/pro-light-svg-icons';

const MenuItems = ({ className = null }) => {
  const { t } = useTranslation('navbar');
  className = ['nav-item', className].join(' ');

  return (
    <>
      <li className={className}>
        <NavLink to="/audit-questions" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faClipboardListCheck} /> <span>{t('auditQuestions')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/branches" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faProjectDiagram} /> <span>{t('branches')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/deviation-questions" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faQuestionCircle} /> <span>{t('deviationQuestions')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/documents" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faFile} /> <span>{t('documents')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/dpia-projects" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faUserSecret} /> <span>{t('dpias')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/job-titles" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faAddressCard} /> <span>{t('jobTitles')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/job-roles" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faBriefcase} /> <span>{t('jobRoles')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/manual-chapters" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faScroll} /> <span>{t('manualChapters')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/norms" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faGavel} /> <span>{t('norms')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/tables" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faTable} /> <span>{t('tables')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/threats" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faTriangleExclamation} /> <span>{t('threats')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/task-packages" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faBoxesStacked} /> <span>{t('taskPackages')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/norm-requirements" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faBadgeCheck} /> <span>{t('normRequirements')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink
          to="/supplier-license-exports"
          className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}
        >
          <FontAwesomeIcon icon={faFileCertificate} /> <span>{t('supplierLicenseExports')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/data-options" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faCabinetFiling} /> <span>{t('dataOptions')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/data-subjects" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faUserCheck} /> <span>{t('dataSubjects')}</span>
        </NavLink>
      </li>
      <li className={className}>
        <NavLink to="/process-flows" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
          <FontAwesomeIcon icon={faArrowProgress} /> <span>{t('common:processFlows')}</span>
        </NavLink>
      </li>
    </>
  );
};

MenuItems.propTypes = {
  className: PropTypes.string,
};

export default MenuItems;
