const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const request = (url, options = {}, headers = defaultHeaders) => {
  options = {
    method: 'POST',
    ...options,
    headers,
  };

  return fetch(url, options)
    .then((r) => (r.status === 200 ? r.json() : r))
    .then((json) => [json, null])
    .catch((error) => [null, error]);
};

const url = '/graphql';

export const graphqlRequest = async (query, variables, controller) => {
  const options = {
    body: JSON.stringify({
      query,
      variables,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    signal: controller !== undefined ? controller.signal : undefined,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === 200) {
      const json = await response.json();

      if (json.errors === undefined) {
        return [json.data, null];
      } else {
        console.error(json.errors);
        throw new Error(json.errors);
      }
    } else {
      return [null, response];
    }
  } catch (error) {
    return [null, error];
  }
};
